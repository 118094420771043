import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import quote from '../css/images/quote.png';
import defaultClientImage from '../css/images/client-1.png'; // Import the placeholder image

function Client() {
  const clientSectionRef = useRef(null);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://195.35.37.39:8888/api/client2/list?page=1');
        if (response.data && response.data.success) {
          setClients(response.data.result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (window.location.hash === '#client' && clientSectionRef.current) {
      clientSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const handleImageError = (event) => {
    event.target.src = defaultClientImage;
  };

  return (
    <section className="client_section layout_padding-bottom" id="client" ref={clientSectionRef}>
      <div className="container">
        <div className="heading_container">
          <h2 style={{ color: 'purple' }}>What Clients Say</h2>
        </div>
        <div className="client_container">
          <div className="carousel-wrap">
            <div className="owl-carousel">
              {clients.map((client) => (
                <div className="item" key={client.id}>
                  <div className="box">
                    <div className="img-box">
                      <img
                        src={`http://195.35.37.39:8888/uploads/${client.image}`}
                        alt=""
                        onError={handleImageError}
                        style={{ borderRadius: '50%', width: '150px', height: '150px', objectFit: 'cover' }}
                      />
                    </div>
                    <div className="detail-box">
                      <h3 style={{ color: 'purple' }}>{client.title}</h3>
                      <p>{client.description}</p>
                      <img src={quote} alt="" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Client;
