import React, { useRef, useEffect } from 'react';
import about from '../css/images/1.png'

function Contact() {
  const contactSectionRef = useRef(null);

  useEffect(() => {
    // Check if the hash is '#about' (used for navigation)
    if (window.location.hash === '#contact' && contactSectionRef.current) {
      // Scroll to the About section when the component mounts
      contactSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <section className="contact_section layout_padding-bottom" id="contact" ref={contactSectionRef}>
      <div className="container">
        <div className="heading_container">
          <h2 style={{ color: 'purple' }}>Any Problems <br /> Any Questions</h2>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-5 offset-md-3">
            <div className="contact_form">
              <h4>Get In touch</h4>
              <form action="">
                <input type="text" placeholder="Name" />
                <input type="text" placeholder="Phone Number" />
                <input type="text" placeholder="Message" className="message_input" />
                <button style={{ color: 'yellow' , backgroundColor: 'purple' }}>Send</button>
              </form>
            </div>
          </div>
          {/* <div className="col-md-6 px-0">
            <div className="img-box">
             <img src={about} alt="" />

            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default Contact;
