import React, { useRef, useEffect } from 'react';
import db from '../css/images/delivery-man-black.png'
import dw from '../css/images/delivery-man-white.png'
import sb from '../css/images/shield-black.png'
import sw from '../css/images/shield-white.png'
import rb from '../css/images/repairing-service-black.png'
import rw from '../css/images/repairing-service-white.png'
function Why() {
  const whySectionRef = useRef(null);

  useEffect(() => {
    // Check if the hash is '#about' (used for navigation)
    if (window.location.hash === '#why' && whySectionRef.current) {
      // Scroll to the About section when the component mounts
      whySectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <section className="why_section layout_padding" id="why" ref={whySectionRef}>
      <div className="container">
        <div className="heading_container">
          <h2 style={{ color: 'purple' }}>
            Why <br /> {/* Use a self-closing tag for <br /> */}
            Choose Us
          </h2>
        </div>
        <div className="why_container">
          <div className="box">
            <div className="img-box">
              <img src={dw} alt="" className="img-1" /> {/* Use className instead of class */}
              <img src={db} alt="" className="img-2" /> {/* Use className instead of class */}
            </div>
            <div className="detail-box">
              <h5 style={{ color: 'purple' }}>
              Safety First
              </h5>
              <p>
              Your safety is our top priority. Our drivers are trained, licensed, and experienced, and our vehicles are regularly inspected to ensure the highest safety standards.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="img-box">
              <img src={sw} alt="" className="img-1" /> {/* Use className instead of class */}
              <img src={sb} alt="" className="img-2" /> {/* Use className instead of class */}
            </div>
            <div className="detail-box">
              <h5 style={{ color: 'purple' }}>
              Reliability              </h5>
              <p>
              We take pride in our punctuality. You can depend on us to arrive on time, every time, and get you to your destination promptly.
              </p>
            </div>
          </div>
          <div className="box">
            <div className="img-box">
              <img src={rw} alt="" className="img-1" /> {/* Use className instead of class */}
              <img src={rb} alt="" className="img-2" /> {/* Use className instead of class */}
            </div>
            <div className="detail-box">
              <h5 style={{ color: 'purple' }}>
                24x7 support
              </h5>
              <p>
           Our dedicated customer service team is available around the clock to assist with reservations, inquiries, and any special requests you may have.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Why;
