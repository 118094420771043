import React, { useRef, useEffect } from 'react';
import mobile from '../css/images/mobile.png'

function AppSection() {
  const appSectionRef = useRef(null);

  useEffect(() => {
    // Check if the hash is '#about' (used for navigation)
    if (window.location.hash === '#app' && appSectionRef.current) {
      // Scroll to the About section when the component mounts
      appSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <section className="app_section layout_padding2" id="app" ref={appSectionRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="detail-box">
              <h2 style={{ color: 'yellow' }}>Download Our app</h2>
              {/* App details and download links go here */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="img-box">
              <img src={mobile} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AppSection;
