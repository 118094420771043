import React, { useRef, useEffect } from 'react';
import fb from '../css/images/fb.png'
import twitter from '../css/images/twitter.png'
import linkedin from '../css/images/linkedin.png'
import instagram from '../css/images/instagram.png'

function Info({ scrollToSection }) {
  const infoSectionRef = useRef(null);

  useEffect(() => {
    // Check if the hash is '#info' (used for navigation)
    if (window.location.hash === '#info' && infoSectionRef.current) {
      // Scroll to the Info section when the component mounts
      infoSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // Function to handle link clicks
  const handleLinkClick = (sectionId, event) => {
    event.preventDefault();
    window.location.hash = sectionId;
    scrollToSection(sectionId);
  };

  return (
    <section className="info_section layout_padding-top layout_padding2-bottom" id="info" ref={infoSectionRef}>
      <div className="container">
        <div className="box">
          {/* <div className="info_form">
            <h4>Subscribe Our Newsletter</h4>
            <form action="">
              <input type="text" placeholder="Enter your email" />
              <div className="d-flex justify-content-end">
                <button></button>
              </div>
            </form>
          </div> */}
          <div className="info_links">
            <ul>
              <li className="">
                <a
                  href="#slider"
                  onClick={(e) => handleLinkClick('slider', e)}
                >
                  Home
                </a>
              </li>
              <li className="">
                <a
                  href="#about"
                  onClick={(e) => handleLinkClick('about', e)}
                >
                  About
                </a>
              </li>
              <li className="">
                <a
                  href="#service"
                  onClick={(e) => handleLinkClick('service', e)}
                >
                  Services
                </a>
              </li>
              <li className="">
                <a
                  href="#news"
                  onClick={(e) => handleLinkClick('news', e)}
                >
                  News
                </a>
              </li>
              <li className="">
                <a
                  href="#contact"
                  onClick={(e) => handleLinkClick('contact', e)}
                >
                  Contact Us
                </a>
              </li>
              <li className="">
                <a
                  href="#why"
                  onClick={(e) => handleLinkClick('why', e)}
                >
Why Us                </a>
              </li>
            </ul>
          </div>
          <div className="info_social">
            <div>
              <a href="https://www.facebook.com/david.mzer/" target='_blank'>
                <img src={fb} alt="" />
              </a>
            </div>
            <div>
              <a href="https://twitter.com/AbiyMinas1" target='_blank'>
                <img src={twitter} alt="" />
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/company/etechsc/mycompany//" target='_blank'>
                <img src={linkedin} alt="" />
              </a>
            </div>
            <div>
              <a href="">
                <img src={instagram} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info;
