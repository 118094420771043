import React, { useEffect } from 'react';
import Header from './components/Header';
import Slider from './components/Slider.js';
import About from './components/About';
import Service from './components/Service';
import News from './components/News';
import Client from './components/Client';
import Contact from './components/Contact';
import AppSection from './components/AppSection';
import Why from './components/Why';
import Info from './components/Info';
import Footer from './components/Footer';
import './css/bootstrap.css';
import './css/responsive.css';
import './css/style.css';

function App() {
  // Scroll to a specific section when a link is clicked
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    
    <div className="App">
      <Header scrollToSection={scrollToSection} />
      <Slider />
      <About />
      <Service />
      <News />
      <Client />
      <Contact />
      <AppSection />
      <Why />
      <Info scrollToSection={scrollToSection}  />
      <Footer />
    </div>
  );
}

export default App;
