

import logo from '../css/images/logo-removebg-preview.png';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React, {  useEffect } from "react";
import { Navbar, Nav } from 'react-bootstrap';

function Header({ scrollToSection }) {
  const handleLinkClick = (sectionId, event) => {
    event.preventDefault();
    window.location.hash = sectionId;
    scrollToSection(sectionId);
  };
  const menuBackground = {
    backgroundColor: 'transparent', // Set a transparent background color for the whole menu
  };

  const homeToWhyBackground = {
    backgroundColor: 'purple', // Set the background color to purple for the specified range
    borderRadius: '10px', // Adding border-radius for curved edges
    padding: '5px 15px', 
  };
  const logoStyle = {
    marginRight: 'auto', // Pushes the logo to the right, aligning menu items to the left of the logo
  };

  const menuTextColor = {
    color: '#FFFF00', // Set the text color to yellow (hex value)
  };
  useEffect(() => {
    // Initialize Bootstrap's collapse functionality after the component has mounted
    const bootstrapCollapse = document.getElementById('navbarSupportedContent');
    bootstrapCollapse?.addEventListener('shown.bs.collapse', () => {
      // Add any necessary logic when the mobile menu is shown (if needed)
    });

    bootstrapCollapse?.addEventListener('hidden.bs.collapse', () => {
      // Add any necessary logic when the mobile menu is hidden (if needed)
    });

    return () => {
      // Remove event listeners when the component unmounts
      bootstrapCollapse?.removeEventListener('shown.bs.collapse', () => {});
      bootstrapCollapse?.removeEventListener('hidden.bs.collapse', () => {});
    };
  }, []);
  // Inline CSS for the smaller logo on mobile devices
  const mobileLogoStyle = {
    width: '150px', // Adjust the width as needed
    height: 'auto', // Maintain aspect ratio
  };

  return (
    <header className="header_section">
    <div className="container-fluid"  style={{
        
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1000 // Adjust z-index if needed
        }}>
      <Navbar expand="lg" className="custom_nav-container" style={menuBackground}>
        <Navbar.Brand href="#" style={logoStyle}>
          <img
            className="navbar-brand"
            src={logo}
            alt=""
            style={mobileLogoStyle}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent">
          <Nav className="ml-auto" style={homeToWhyBackground}>
                <li className="nav-item">
                  <a
                    href="#slider"
                    onClick={(e) => handleLinkClick('slider', e)}
                    className="nav-link yellow-text"
                    style={menuTextColor}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#about"
                    onClick={(e) => handleLinkClick('about', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#service"
                    onClick={(e) => handleLinkClick('service', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    Service
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#news"
                    onClick={(e) => handleLinkClick('news', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    News
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#client"
                    onClick={(e) => handleLinkClick('client', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    Client
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#contact"
                    onClick={(e) => handleLinkClick('contact', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    Contact
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#app"
                    onClick={(e) => handleLinkClick('app', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    App
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#why"
                    onClick={(e) => handleLinkClick('why', e)}
                    className="nav-link"
                    style={menuTextColor}
                  >
                    Why
                  </a>
                </li>
                </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </header>
  );
}

export default Header;

